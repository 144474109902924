/* eslint-disable react/jsx-no-target-blank */
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

const Footer = () => {
  const intl = useIntl();

  return (
    <footer className="pt-32 pb-sa-b-40 text-center text-sm md:pb-44">
      <small>
        <span>&copy; {new Date().getFullYear()} Mario A. Gonzalez</span>
        <span>
          &nbsp;|&nbsp;
          <Link to={`/${intl.locale}/privacy-policy`}>{intl.formatMessage({ id: 'privacy-policy-label' })}</Link>
        </span>
        <span>
          &nbsp;|&nbsp;
          <a href="https://fsvdr.me" target="_blank">
            Webmaster <abbr title="Fernando Saavedra">FSVDR</abbr>
          </a>
        </span>
      </small>
    </footer>
  );
};

export default Footer;
