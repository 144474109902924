import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SiteLink from './SiteLink';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

const MobileMenu = ({ isActive, onClose }) => {
  const intl = useIntl();

  useEffect(() => {
    document.body.style.overflow = isActive ? 'hidden' : 'initial';
    document.body.style.height = isActive ? '100vh' : 'auto';

    return () => {
      document.body.style.overflow = 'initial';
      document.body.style.height = 'auto';
    };
  }, [isActive]);

  return (
    <>
      <nav
        className={`mobile-menu fixed top-0 left-0 flex flex-col flex-nowrap w-screen ${
          isActive ? 'h-screen pb-96' : 'h-0'
        } overflow-hidden transition-all ease-in-out duration-700 z-10 md:hidden`}
      >
        <div className="w-full flex-1 bg-red-darkest">
          <div className={`${isActive ? 'opacity-100 delay-500' : 'opacity-0 delay-75'} transition-opacity`}>
            <div className="absolute w-4/5 max-w-4xl top-0 left-1/2 transform -translate-x-1/2">
              <StaticImage src="../images/blood.png" alt="" loading="eager" placeholder="none" />
            </div>

            <div className="block w-80 mx-auto mt-3 mb-7 md:flex-2 md:order-1 md:w-full max-w-lg">
              <StaticImage src="../images/logo.png" alt="Nihil Negativum" loading="eager" placeholder="none" />
            </div>

            <p className="relative text-sm text-center">{intl.formatMessage({ id: 'site-description' })}</p>

            <button type="button" onClick={onClose} className="text-sm absolute top-32 right-8 md:hidden">
              Close
            </button>
          </div>

          <ul
            className={`absolute top-1/2 left-1/2 w-full flex flex-col justify-center items-center gap-y-3 opacity-0 ${
              isActive ? 'opacity-100 delay-500' : 'opacity-0 delay-75'
            } transform -translate-x-1/2 -translate-y-1/2 transition-opacity z-50`}
          >
            <SiteLink
              text={intl.formatMessage({ id: 'menu-about' })}
              slug={`/${intl.locale}/${intl.formatMessage({ id: 'menu-about' }).toLowerCase()}`}
            />
            <SiteLink
              text={intl.formatMessage({ id: 'menu-characters' })}
              slug={`/${intl.locale}/${intl.formatMessage({ id: 'menu-characters' }).toLowerCase()}`}
            />
            <SiteLink
              text={intl.formatMessage({ id: 'menu-archive' })}
              slug={`/${intl.locale}/${intl.formatMessage({ id: 'menu-archive' }).toLowerCase()}`}
            />
            <SiteLink text={'blog'} slug={`/${intl.locale}/blog`} />
            <SiteLink
              text={intl.formatMessage({ id: 'menu-contact' })}
              slug={`/${intl.locale}/${intl.formatMessage({ id: 'menu-contact' }).toLowerCase()}`}
            />
          </ul>
        </div>

        <StaticImage
          src="../images/blood.png"
          alt=""
          loading="eager"
          className="absolute bottom-0 w-110 transform -translate-x-1/2 left-1/2 h-96"
          layout="fullWidth"
          imgClassName="object-top"
        />
      </nav>
    </>
  );
};

MobileMenu.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default MobileMenu;
