import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import SiteLink from './SiteLink';
import MobileMenu from './MobileMenu';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';

const Header = ({ siteTitle, sticky }) => {
  const intl = useIntl();
  const [isMenuActive, setIsMenuActive] = useState(false);

  return (
    <>
      <header
        className={`${
          sticky ? 'sticky' : 'relative'
        } top-0 left-0 flex flex-col items-center pt-3 gap-y-7 max-w-screen-xl mx-auto md:flex-row md:justify-between md:px-7`}
      >
        <div className={`${sticky ? 'fixed' : 'absolute'} w-4/5 max-w-4xl transform -translate-x-1/2 top-0 left-1/2`}>
          <StaticImage src="../images/blood.png" alt="" loading="eager" placeholder="none" />
        </div>

        <p className="order-1 relative text-sm text-left md:flex-1 md:order-0">
          {intl.formatMessage({ id: 'site-description' })}
        </p>

        <Link
          to={`/${intl.locale}`}
          className="order-0 justify-self-center w-80 md:flex-2 md:order-1 md:w-full max-w-lg"
          aria-label={intl.formatMessage({ id: 'home-label' })}
        >
          <StaticImage src="../images/logo.png" alt={siteTitle} loading="eager" placeholder="none" />
        </Link>

        <button
          type="button"
          onClick={() => setIsMenuActive(true)}
          className="text-sm absolute top-32 right-8 md:hidden"
        >
          {intl.formatMessage({ id: 'menu-open' })}
        </button>

        <nav className="hidden justify-self-end text-center text-sm md:flex md:flex-row md:justify-end md:flex-1 md:order-2">
          <ul className="flex flex-col w-min items-center gap-y-1">
            <SiteLink
              text={intl.formatMessage({ id: 'menu-about' })}
              slug={`/${intl.locale}/${intl.formatMessage({ id: 'menu-about' }).toLowerCase()}`}
            />
            <SiteLink
              text={intl.formatMessage({ id: 'menu-characters' })}
              slug={`/${intl.locale}/${intl.formatMessage({ id: 'menu-characters' }).toLowerCase()}`}
            />
            <SiteLink
              text={intl.formatMessage({ id: 'menu-archive' })}
              slug={`/${intl.locale}/${intl.formatMessage({ id: 'menu-archive' }).toLowerCase()}`}
            />
            <SiteLink text={'blog'} slug={`/${intl.locale}/blog`} />
            <SiteLink
              text={intl.formatMessage({ id: 'menu-contact' })}
              slug={`/${intl.locale}/${intl.formatMessage({ id: 'menu-contact' }).toLowerCase()}`}
            />
          </ul>
        </nav>
      </header>

      <MobileMenu isActive={isMenuActive} onClose={() => setIsMenuActive(false)} />
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  sticky: PropTypes.bool,
};

Header.defaultProps = {
  siteTitle: ``,
  sticky: false,
};

export default Header;
