import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const SiteLink = ({ text, slug }) => {
  return (
    <li className="group relative w-min">
      <div className="absolute -left-14 top-1/2 transform -translate-y-1/2 opacity-0 w-8 transition-opacity group-hover:opacity-100">
        <StaticImage src="../images/hand.png" alt="" />
      </div>

      <Link to={`${slug}`}>{text}</Link>
    </li>
  );
};

SiteLink.propTypes = {
  text: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default SiteLink;
